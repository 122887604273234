import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 'appleButton' ]
  static values = {
    cardToken: String,
    appleScriptUrl: 'https://smp-device-content.apple.com/navweb/asset/initAddToAppleWallet.js',
    appleDomain: 'https://apple-pay.apple.com',
    applePartnerId: String,
  }

  connect() {
    this.cardExist = false
    this.isScriptLoaded = false
    this.token = null
    this.isWalletInitialized = false
    this.loadApplePayScript()
    this.checkCardUser()
   
  }

  loadApplePayScript() {
    if (document.querySelector(`script[src='${this.appleScriptUrlValue}']`)) {
      this.scriptLoaded()
      return
    }

    const script = document.createElement('script')
    script.src = this.appleScriptUrlValue
    script.onload = () => this.scriptLoaded()
    document.head.appendChild(script)

    this.script = script
  }

  async checkCardUser() {
    const response = await fetch('/impact_card_tokens/card_exist', {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    })

    const data = await response.json()
    const { cardExist } = data

    if (cardExist) {
      this.addToApplePay()
    }
  }

  scriptLoaded() {
    this.isScriptLoaded = true
    console.log('Apple Pay script loaded successfully.')
  }

  async addToApplePay() {
    
    try {
      await this.fetchTokens()

      if (this.cardTokenValue) {
        this.initializeApplePay()
      } else {
        console.error('Card token is empty. Cannot initialize Apple Pay.')
      }
    } catch (error) {
      console.error('Error in addToApplePay:', error)
    }
  }

  initializeApplePay() {
    const buttonId = this.appleButtonTarget.id

    if (!this.isScriptLoaded) {
      console.error('Apple Pay script is not loaded yet.')
      return
    }

    if (this.isWalletInitialized) {
      console.warn('Apple Wallet has already been initialized. Ignoring subsequent attempts.')
      return
    }

    try {
      window.initAddToAppleWallet({
        partnerId: this.applePartnerIdValue,
        domain: this.appleDomainValue,
        buttonId,
        jwtResolver: this.tokenizeAppleWallet.bind(this),
        resultResolver: this.handleResult.bind(this),
      })

      this.isWalletInitialized = true
    } catch (error) {
      console.error('Error initializing Apple Wallet:', error)
    }
  }

  async fetchTokens() {
    try {
      const response = await fetch('/impact_card_tokens/fetch_token', {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      })

      if (!response.ok) {
        console.error(`Error fetching token: ${response.statusText}`)
        return
      }

      const data = await response.json()
      const { appleToken, cardToken } = data

      this.cardTokenValue = cardToken
      this.applePartnerIdValue = appleToken
    } catch (error) {
      console.error('Fetch Tokens Error:', error)
    }
  }

  async tokenizeAppleWallet() {
    try {
      const response = await fetch('/impact_card_tokens/tokenize_apple_pay', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector("meta[name='csrf-token']").content,
        },
        body: JSON.stringify({ card_token: this.cardTokenValue }),
      })

      if (!response.ok) {
        throw new Error(`Error tokenizing Apple Wallet: ${response.statusText}`)
      }

      const data = await response.json()
      if (!data?.token) {
        throw new Error('Invalid tokenization response.')
      }

      this.token = data.token
      return data
    } catch (error) {
      console.error('Error tokenizing Apple Wallet:', error)
      throw error
    }
  } 

  handleResult(result) {
    if (result?.status === 200) {
      console.log('Apple Pay Success:', result)
    } else {
      console.error('Apple Pay Error:', result?.message || 'Unknown error')
    }
  }
}
