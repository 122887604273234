import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    cardToken: String,
    googlePayId: String
  }

  connect () {
    console.log('gpay connected')
  }

  async addToGpay () {
    try {
      await this.fetchTokens()
      if (this.cardTokenValue) {
        this.initializeGooglePay()
      } else {
        console.error('Card token is empty. Cannot initialize Google Pay.')
      }
    } catch (error) {
      console.error('Error in addToGpay:', error)
    }
  }

  initializeGooglePay () {
    const randomSessionId = Math.random().toString(16).substring(2)

    window.googlepay.openAppWindow({
      integratorId: this.googlePayIdValue,
      isTestEnvironment: false,
      tokenSetting: 1,
      cardSetting: 1,
      clientSessionId: randomSessionId,
      onSessionCreated: this.onSessionCreated.bind(this),
      onSuccess: this.onSuccess.bind(this),
      onFailure: this.onFailure.bind(this),
      onCancel: this.onCancel.bind(this),
      onFinish: this.onFinish.bind(this)
    })
  }

  onSessionCreated (payload) {
    payload.card_token = this.cardTokenValue
    console.log(`Session: ${JSON.stringify(payload)}`)
    this.tokenizeGooglePay(payload)
  }

  onSuccess (payload) {
    console.log(`Success: ${JSON.stringify(payload)}`)
  }

  onFailure (payload) {
    console.log(`Failure: ${JSON.stringify(payload)}`)
  }

  onCancel (payload) {
    console.log(`Cancel: ${JSON.stringify(payload)}`)
  }

  onFinish (payload) {
    console.log(`Finish: ${JSON.stringify(payload)}`)
    window.googlepay.closeAppWindow()
  }

  async fetchTokens () {
    try {
      const response = await fetch('/impact_card_tokens/fetch_token', {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      })

      if (!response.ok) {
        console.error(`Error fetching token: ${response.statusText}`)
        return
      }

      const data = await response.json()
      console.log('API Response:', data)
      const { gpayToken, cardToken } = data

      this.cardTokenValue = cardToken
      this.googlePayIdValue = gpayToken

      console.log('Card Token:', this.cardTokenValue)
      console.log('Google Pay ID:', this.googlePayIdValue)
    } catch (error) {
      console.error('Fetch Tokens Error:', error)
    }
  }

  tokenizeGooglePay (payload) {
    fetch('/impact_card_tokens/tokenize_gpay', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector("meta[name='csrf-token']").content
      },
      body: JSON.stringify(payload)
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Tokenization Success:', data)
      })
      .catch((error) => {
        console.error('Tokenization Error:', error)
      })
  }
}
